import React from "react"
import { Link } from "gatsby"

export default function Layout({ children }) {
  return (
    <div class='social'>
      <a href="https://twitter.com/moodymonsteras">
        <img src="/images/footer-twitter.svg"></img>
      </a>
      <a href="https://opensea.io/collection/moodymonsteras">
        <img src="/images/footer-opensea.svg"></img>
      </a>
      <a href="https://discord.gg/g5hsye4xUN">
        <img src="/images/footer-discord.svg"></img>
      </a>
    </div>
  )
}