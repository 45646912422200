import axios from "axios"
import GetJwtToken from "../utils/jwt.js"

class Api {

  constructor() {

    // Create Axios instance w/ base url and headers
    this.axiosInstance = axios.create({
      baseURL: process.env.GATSBY_API_URL,
      headers: {
        'Authorization': `Bearer ${GetJwtToken()}`
      }
    })

    // Create Axios intercepter to handle 401 redirects
    this.axiosInstance.interceptors.response.use(response => {
      return response;
    }, error => {
     if (error.response.status === 401) {
      if (typeof window !== 'undefined') {
        window.location = process.env.GATSBY_AUTH_URL;
      }
     }
     return error;
    });

  }

  getUser() {
    return this.axiosInstance.get('/user');
  }

  getCheckout(planPriceId) {
    return this.axiosInstance.get('/checkout/'+planPriceId);
  }

  getBilling() {
    return this.axiosInstance.get('/billing');
  }

  getTemplates() {
    return this.axiosInstance.get('/template');
  }

  getDesigns() {
    return this.axiosInstance.get('/design');
  }

  getFonts(fonts) {
    return this.axiosInstance.get('/fonts', { params: { fonts: fonts.join(',') } });
  }

  postTemplate(templateData) {
    return this.axiosInstance.post('/template', templateData);
  }

  deleteTemplate(templateId) {
    return this.axiosInstance.delete('/template/'+templateId);
  }

  deleteDesign(designId) {
    return this.axiosInstance.delete('/design/'+designId);
  }

}

export default Api