
export default function GetJwtToken() {

  const _windowLocationHash = typeof window !== 'undefined' && window.location.hash
  const _windowLocalStorage = typeof window !== 'undefined' && window.localStorage

  var url_token = new URLSearchParams(_windowLocationHash).get("#id_token")

  if (typeof window !== 'undefined') {
    if (url_token) {
      _windowLocalStorage.setItem('jwt', url_token)
    } else {
      url_token = _windowLocalStorage.getItem('jwt')
    }
  }

  return url_token
}