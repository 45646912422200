import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../components/social"

const ListLink = props => (
  <li>
    <Link activeClassName='active' to={props.to}>{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  return (
    <div class='layout'>
      <Helmet>
        <title>Moody Monsteras</title>
      </Helmet>
      <header>
          <Link class='logo' to="/">
            <h3>MoodyMonsteras</h3>
          </Link>
          <ul class='nav-links'>
            <ListLink to="/">Home</ListLink>
            <ListLink to="/mint">Mint</ListLink>
            <ListLink to="/gallery">Gallery</ListLink>
            <ListLink to="/stake">Stake</ListLink>
          </ul>
        </header>
      {children}
        <footer>
          <Social/>
        </footer>
    </div>
  )
}